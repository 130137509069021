<template v-if="isDataLoaded">
  <div class="p-4">
    <warehouse-dashboard-layout
      :pageTitle="pageTitle"
      :lineChart="false"
      :additionalIfo="true"
    ></warehouse-dashboard-layout>
  </div>
</template>

<script>
import WarehouseDashboardLayout from "@/own/components/dashboard/WarehouseDashboardLayout.vue";
import { SET_DASHBOARD_URL } from "@/core/services/store/warehouseDashboard.module";

export default {
  components: { WarehouseDashboardLayout },
  name: "Warehouse",
  data() {
    return {
      pageTitle: {
        header: "Warehouse Dashboard",
        subHeader: "Home - Dashboards",
      },
    };
  },
  computed: {
    isDataLoaded: function () {
      return (
        this.$store.getters.getDELIVERYTableData.length > 0 &&
        this.$store.getters.getDELIVERYFilter.length > 0
      );
    },
  },
  created() {
    this.$store.commit(SET_DASHBOARD_URL, "/dashboards/dashboardWarehouse");
  },
};
</script>
