<template>
  <div v-if="serverData">
    <div
      style="background-color: #fff"
      class="rounded-md mb-3 mb-xl-0 position-relative mr-1 mr-lg-2"
      @click="navigateToOrders"
    >
      <div class="h-175px w-100 w-xxl-75 p-7 p-pxxl-9">
        <div class="d-flex flex-column justify-content-between h-100">
          <div class="d-flex justify-content-between">
            <p class="text-title">
              {{ serverData.title }}
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <div>
              <template v-if="serverData.basic">
                <div v-if="serverData.additional" class="d-flex">
                  <p class="text-total mb-0">
                    {{ serverData?.basic?.title }}
                  </p>
                </div>
              </template>
              <div>
                <h1 class="text-value">{{ serverData?.basic?.value }}</h1>
              </div>
            </div>

            <div>
              <template v-if="serverData.additional">
                <div class="d-flex justify-content-center">
                  <p class="text-total mb-0">
                    {{ serverData?.additional?.title }}
                  </p>
                </div>
              </template>
              <div class="d-flex justify-content-center">
                <h1 class="text-value">
                  {{ serverData?.additional?.value }}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <v-skeleton-loader v-else type="card"></v-skeleton-loader>
</template>

<script>
// import { getToken } from "@/core/services/jwt.service";
// import axios from "axios";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { SET_TABLE_FILTER } from "@/core/services/store/orders.module";
import { SET_FILTER_BUTTON_DISABLED } from "@/core/services/store/warehouseDashboard.module";
import ordersFilterMixin from "@/own/mixins/ordersFilterMixin";
// import WarehouseRadialChart from "@/own/components/dashboard/WarehouseRadialChart.vue";

// import Swal from "sweetalert2";
export default {
  name: "WarehouseCounterChart",
  // components: { WarehouseRadialChart },
  props: {
    item: { required: true },
    filterData: { required: true },
    index: { required: true },
    lineChart: { required: true },
    additionalIfo: { required: true },
    updateCounter: { required: true },
  },
  mixins: [ordersFilterMixin],
  data: () => ({ serverData: null }),
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.loadDataFromServer();
  },
  mounted() {
    this.$parent.$on("filterButtonClicked", () => {
      this.loadDataFromServer();
    });
  },
  beforeDestroy() {
    this.$parent.$off("filterButtonClicked");
  },
  methods: {
    loadDataFromServer() {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.serverData = null;
      let data = this.filterData;
      ApiService.post(`${this.item.url}`, data)
        .then((response) => {
          this.serverData = response.data.chart;
          setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          }, 1000);
        })
        .catch(() => {})
        .finally(() => {
          this.$store.commit(SET_FILTER_BUTTON_DISABLED, false);
        });
    },
    navigateToOrders() {
      const status = this.getOrderStatus(this.item.name);
      if (status) {
        this.$store.commit(SET_PAGE_LOADING, true);

        const FILTERS = [];
        let range = null;

        if (this.filterData.date_range_type) {
          range = {
            name: "date_range.date",
            value: this.filterData.date_range,
          };
        }
        FILTERS.push(range);
        FILTERS.push(status);
        FILTERS.push({
          name: "date_range.select",
          value: this.serverData.datarangetype,
        });
        if (this.filterData.client) {
          FILTERS.push({ name: "client", value: [this.filterData.client] });
        }
        if (this.filterData.carrier) {
          FILTERS.push({ name: "carrier", value: [this.filterData.carrier] });
        }
        this.$store.commit(SET_TABLE_FILTER, FILTERS);
        this.$router.push("/fulfillment/orders");
      }
    },
    addComma(number) {
      // Convert number to string
      let strNumber = number.toString();

      // Check if number is greater than 3 digits
      if (strNumber.length > 3) {
        // Insert comma at the appropriate index
        let parts = [];
        while (strNumber.length > 3) {
          parts.unshift(strNumber.slice(-3));
          strNumber = strNumber.slice(0, -3);
        }
        parts.unshift(strNumber);
        strNumber = parts.join(",");
      }

      return strNumber;
    },
    getTotal(val) {
      if (val) {
        return val;
      } else if (!val && this.serverData.additional) {
        return 1;
      }
      return "999";
    },
  },
  computed: {
    isDataLoaded: function () {
      return !!this.serverData;
    },
    series() {
      return [45];
    },
    chartOptions() {
      return {
        chart: {
          type: "radialBar",
          width: 50,
          height: 50,
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: true, // Set to true to display labels
        },
        annotations: {},
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 10,
              size: "65%",
              background: "#fff",
            },
            dataLabels: {
              show: true,
              name: {
                show: true,
                fontSize: "16px",
              },
              value: {
                show: false,
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["45%"],
      };
    },
  },
  watch: {
    // filterData() {
    //   this.serverData = null;
    //   this.loadDataFromServer();
    // },
    updateCounter: function (val) {
      if (val !== 0) {
        this.loadDataFromServer();
      }
    },
  },
};
</script>

<style scoped>
.border-circular {
  border-radius: 12px;
}
.text-title {
  margin: 0px;
  font-weight: 600;
  line-height: 1.57143;
  font-size: 16.5px;
  font-family: "Roboto", sans-serif;
  color: #6f6b7d;
}
.text-total {
  font-size: 16px;
  font-weight: 600;
  color: #a5a3ae !important;
}
.text-value {
  margin: 0px;
  font-weight: 700;
  line-height: 1.57143;
  font-size: 2.9rem;
  font-family: "roboto";
  color: #7e04b7;
}

.rounded-md {
  border-radius: 16px;
  box-shadow: rgba(150, 160, 170, 0.2) 0px 0px 2px 0px,
    rgba(150, 160, 170, 0.12) 0px 12px 24px -4px;
}
.icon-md {
  font-size: 2rem !important;
}
.text-dark-55 {
  color: #d1d1da;
}
</style>

<style>
@media (min-width: 2100px) {
  .w-xxl-75 {
    width: 75% !important;
  }
}
</style>
